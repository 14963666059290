import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, Suspense as _Suspense, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  class: "container",
  style: {"min-height":"65dvh"}
}
const _hoisted_2 = {
  class: "row q-col-gutter-y-lg overflow-hidden",
  style: {"margin":"3rem 0 0 0"}
}
const _hoisted_3 = { class: "col-12 col-sm-6" }
const _hoisted_4 = {
  class: "flex column justify-between full-height",
  style: {"row-gap":"52px"}
}
const _hoisted_5 = { class: "full-width" }
const _hoisted_6 = ["href"]
const _hoisted_7 = ["src"]
const _hoisted_8 = ["href"]
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  class: "flex justify-center column items-center",
  style: {"row-gap":"24px"}
}
const _hoisted_11 = { class: "full-width q-px-xl" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 2 }
const _hoisted_15 = { class: "container q-my-xl" }
const _hoisted_16 = {
  class: "flex justify-center q-py-md",
  style: {"column-gap":"52px","row-gap":"24px"}
}
const _hoisted_17 = ["src"]
const _hoisted_18 = ["src"]
const _hoisted_19 = ["src"]
const _hoisted_20 = ["src"]
const _hoisted_21 = { class: "c-section-bg-1" }
const _hoisted_22 = { class: "container q-py-xl" }
const _hoisted_23 = {
  class: "flex column q-my-xl",
  style: {"row-gap":"120px"}
}
const _hoisted_24 = { class: "full-width" }
const _hoisted_25 = { class: "row q-col-gutter-xl items-center" }
const _hoisted_26 = { class: "col-12 col-sm-6" }
const _hoisted_27 = { class: "full-width" }
const _hoisted_28 = { class: "col-12 col-sm-6" }
const _hoisted_29 = { class: "text-center q-pa-sm bordered rounded-borders overflow-hidden bg-white" }
const _hoisted_30 = { class: "full-width" }
const _hoisted_31 = { class: "row q-col-gutter-xl items-center" }
const _hoisted_32 = { class: "col-12 col-sm-6" }
const _hoisted_33 = { class: "q-mt-lg" }
const _hoisted_34 = { class: "full-width" }
const _hoisted_35 = { class: "col-12 col-sm-6" }
const _hoisted_36 = { class: "c-fancy-background rounded-borders bordered flex flex-center q-pa-lg" }
const _hoisted_37 = {
  style: {"max-width":"500px","width":"100%"},
  class: "bordered rounded-borders overflow-hidden"
}
const _hoisted_38 = { class: "full-width" }
const _hoisted_39 = {
  class: "flex items-center text-center justify-center",
  style: {"gap":"12px"}
}
const _hoisted_40 = { class: "bg-white rounded-borders q-pa-md c-standout" }
const _hoisted_41 = ["src"]
const _hoisted_42 = { class: "bg-white rounded-borders q-pa-md c-standout" }
const _hoisted_43 = ["src"]
const _hoisted_44 = { class: "bg-white rounded-borders q-pa-md c-standout" }
const _hoisted_45 = ["src"]
const _hoisted_46 = { class: "bg-white rounded-borders q-pa-md c-standout" }
const _hoisted_47 = ["src"]
const _hoisted_48 = { class: "bg-white rounded-borders q-pa-md c-standout" }
const _hoisted_49 = ["src"]
const _hoisted_50 = { class: "c-section-bg-2" }
const _hoisted_51 = { class: "container q-py-xl" }
const _hoisted_52 = {
  class: "text-center",
  style: {"margin-bottom":"104px","margin-top":"calc(104px - 48px)"}
}
const _hoisted_53 = { class: "text-h1 text-weight-bolder highlighted-title" }
const _hoisted_54 = { class: "relative-position" }
const _hoisted_55 = {
  class: "absolute",
  style: {"bottom":"calc(100% - 15px)","left":"-5px"}
}
const _hoisted_56 = {
  class: "flex column q-my-xl",
  style: {"row-gap":"120px"}
}
const _hoisted_57 = { class: "full-width" }
const _hoisted_58 = { class: "row q-col-gutter-xl items-center" }
const _hoisted_59 = { class: "col-12 col-sm-6" }
const _hoisted_60 = { class: "q-mt-lg" }
const _hoisted_61 = { class: "col-12 col-sm-6" }
const _hoisted_62 = { class: "full-width" }
const _hoisted_63 = { class: "col-12 col-sm-6" }
const _hoisted_64 = { class: "c-fancy-background rounded-borders bordered flex flex-center q-px-md" }
const _hoisted_65 = { class: "flex items-center justify-around no-wrap full-width relative-position" }
const _hoisted_66 = ["src"]
const _hoisted_67 = { class: "c-section-bg-3" }
const _hoisted_68 = { class: "container q-py-xl" }
const _hoisted_69 = {
  class: "flex column q-my-xl",
  style: {"row-gap":"120px"}
}
const _hoisted_70 = { class: "full-width" }
const _hoisted_71 = { class: "row q-col-gutter-xl items-center" }
const _hoisted_72 = { class: "col-12 col-sm-6" }
const _hoisted_73 = { class: "full-width" }
const _hoisted_74 = { class: "col-12 col-sm-6" }
const _hoisted_75 = { class: "col-12 col-sm-6" }
const _hoisted_76 = { class: "q-mt-lg" }
const _hoisted_77 = { class: "container q-py-xl" }
const _hoisted_78 = {
  class: "full-width flex column",
  style: {"row-gap":"120px"}
}
const _hoisted_79 = { class: "row q-col-gutter-xl items-center" }
const _hoisted_80 = { class: "col-12" }
const _hoisted_81 = { class: "row q-col-gutter-lg justify-around" }
const _hoisted_82 = { class: "col-12 col-sm text-center" }
const _hoisted_83 = { class: "flex column full-height items-center" }
const _hoisted_84 = { class: "col-12 col-sm text-center" }
const _hoisted_85 = { class: "flex column full-height items-center" }
const _hoisted_86 = { class: "col-12 col-sm text-center" }
const _hoisted_87 = { class: "flex column full-height items-center" }
const _hoisted_88 = { class: "q-py-xl" }
const _hoisted_89 = { class: "q-my-xl" }
const _hoisted_90 = { class: "container q-mb-lg" }
const _hoisted_91 = { class: "q-py-xl bg-grey-3" }

import {ref} from 'vue';
import {APPLE_STORE_URL, GOOGLE_PLAY_URL} from 'src/apps/core/static';
import BlogFeed from 'components/BlogFeed.vue';
import UserListWidget from 'components/etc/UserListWidget.vue';
import PartnerCarousel from 'components/partners/PartnerCarousel.vue';
import IndexExtrasSliderNoText from 'components/etc/IndexExtrasSliderNoText.vue';
import CSwiperCardsAdverts from 'src/apps/landing/components/start/CSwiperCardsAdverts.vue';
import CSwiperCardsItems from 'src/apps/landing/components/start/CSwiperCardsItems.vue';
import CSwiperCardsServices from 'src/apps/landing/components/start/CSwiperCardsServices.vue';
import TestimonialWidget from 'components/etc/TestimonialWidget.vue';
import BoxEmojiAnimation from 'src/apps/landing/components/start/BoxEmojiAnimation.vue';
import StartFaqs from 'src/apps/landing/components/start/StartFaqs.vue';
import StartServicesAnimation from 'src/apps/landing/components/start/StartServicesAnimation.vue';
import StartServicesAnimationSearch from 'src/apps/landing/components/start/StartServicesAnimationSearch.vue';
import {useMeta} from 'quasar';
import {getPageUrl} from 'src/etc/helper';
import {useRouter} from 'vue-router';


// const typedEl = ref<HTMLElement | null>(null);

import {useMainStore} from 'stores/main';

export const radiusOptions = [5, 10, 20, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500];
const __default__ = {
  async preFetch({store}) {
    const mainStore = useMainStore(store)
    await mainStore.getGoogleReviews()
    return await mainStore.fetchCities()
  },
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'StartPage',
  setup(__props) {

const partner = ref([]);
const swiperCardsTab = ref('vehicles')
const $router = useRouter()


useMeta(() => {
  const link = getPageUrl('home', $router) ?? ''

  return {
    titleTemplate(title) {
      return `Markplatz rund ums Camping | ${title}`;
    },

    meta: {
      equiv: {
        'http-equiv': 'Content-Type',
        content: 'text/html; charset=UTF-8',
      },

      description: {
        name: 'description',
        content:
          'Mit dem Fokus auf Fahrzeugkauf und -verkauf sowie einem wachsenden Angebot ' +
          'von Camping-Zubehör und Dienstleistungen, wollen wir euch die bestmögliche Plattform von und für Camper anbieten.'
      },

      // note: for Open Graph type metadata you will need to use SSR, to ensure page is rendered by the server
      ogTitle: {
        property: 'og:title',

        // optional; similar to titleTemplate, but allows templating with other meta properties
        template(title) {
          return `Markplatz rund ums Camping | ${title}`;
        },
      },
      ogDescription: {
        property: 'og:description',
        template() {
          return 'Mit dem Fokus auf Fahrzeugkauf und -verkauf sowie einem wachsenden Angebot ' +
            'von Camping-Zubehör und Dienstleistungen, wollen wir euch die bestmögliche Plattform von und für Camper anbieten.';
        },
      },

      ogUrl: {
        property: 'og:url',
        template() {
          return link;
        },
      },

      ogImage: {
        property: 'og:image',
        template() {
          return `${require('src/assets/logos/campertrader_logo_final_ocean_600.png') ?? ''}`;
        },
      },

      ogImageUrl: {
        property: 'og:image:url',
        template() {
          return `${require('src/assets/logos/campertrader_logo_final_ocean_600.png') ?? ''}`
        },
      },

      ogImageWidth: {
        property: 'og:image:width',
        template() {
          return '600';
        },
      },

      ogImageType: {
        property: 'og:image:type',
        template() {
          return 'image/png';
        },
      },

      ogImageAlt: {
        property: 'og:image:alt',
        template() {
          return 'Camper Trader Logo';
        },
      },

      ogImageHeight: {
        property: 'og:image:height',
        template() {
          return '176';
        },
      },

      twitterImage: {
        property: 'twitter:image',
        template() {
          return `${require('src/assets/logos/campertrader_logo_final_ocean_600.png') ?? ''}`;
        },
      },
      twitterCard: {
        property: 'twitter:card',
        template() {
          return 'summary_large_image';
        },
      },
      twitterSite: {
        property: 'twitter:site',
        template() {
          return '@CamperTrader_de';
        },
      },
      twitterCreator: {
        property: 'twitter:creator',
        template() {
          return '@CamperTrader_de';
        },
      },
      twitterTitle: {
        property: 'twitter:title',
        template(title) {
          return `Markplatz rund ums Camping | ${title}`;
        },
      },
      twitterDescription: {
        property: 'twitter:description',
        template() {
          return 'Mit dem Fokus auf Fahrzeugkauf und -verkauf sowie einem wachsenden Angebot ' +
            'von Camping-Zubehör und Dienstleistungen, wollen wir euch die bestmögliche Plattform von und für Camper anbieten.';
        },
      },
    },

    link: {
      canonical: {
        rel: 'canonical',
        href: link,
      },
    }
  }

})


return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, null, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-left full-width" }, [
                _createElementVNode("h1", {
                  class: "text-uppercase font-inter text-bold q-my-md",
                  style: {"hyphens":"auto"}
                }, [
                  _createTextVNode(" Dein "),
                  _createElementVNode("span", { class: "text-secondary" }, "Marktplatz"),
                  _createElementVNode("br"),
                  _createTextVNode("rund ums "),
                  _createElementVNode("span", { class: "text-secondary" }, "Camping")
                ]),
                _createElementVNode("h2", {
                  class: "text-weight-light",
                  style: {"font-size":"1.2rem"}
                }, " Camper gebraucht kaufen & Second Hand Camping Zubehör & Dienstleister finden ")
              ], -1)),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", {
                  class: _normalizeClass(["flex items-center full-width", {'justify-center': _ctx.$q.screen.lt.sm}]),
                  style: {"gap":"8px","min-width":"0"}
                }, [
                  _createElementVNode("a", {
                    href: _unref(GOOGLE_PLAY_URL),
                    target: "_blank",
                    style: {"text-decoration":"none"}
                  }, [
                    _createElementVNode("img", {
                      alt: "Download Google Play Store",
                      height: "45px",
                      src: require('src/assets/storeBtns/google-play-badge_200.png')
                    }, null, 8, _hoisted_7)
                  ], 8, _hoisted_6),
                  _createElementVNode("a", {
                    href: _unref(APPLE_STORE_URL),
                    target: "_blank",
                    style: {"text-decoration":"none"}
                  }, [
                    _createElementVNode("img", {
                      alt: "Download Apple Store",
                      height: "45px",
                      src: require('src/assets/storeBtns/apple.svg')
                    }, null, 8, _hoisted_9)
                  ], 8, _hoisted_8),
                  _createElementVNode("div", null, [
                    _createVNode(_component_q_btn, {
                      to: {name: 'search'},
                      style: {"height":"45px","border-radius":"7px","margin-top":"-5px"},
                      class: "font-inter",
                      unelevated: "",
                      color: "tertiary",
                      "no-caps": ""
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode(" Zur Web-Version ")
                      ])),
                      _: 1
                    })
                  ])
                ], 2)
              ]),
              _createElementVNode("div", {
                class: _normalizeClass(["flex column", {'items-center': _ctx.$q.screen.lt.sm, 'q-mx-xl': !_ctx.$q.screen.lt.sm}])
              }, [
                _cache[3] || (_cache[3] = _createElementVNode("div", { class: "text-h3 text-weight-light q-mb-sm" }, "Unsere wachsende Community", -1)),
                _createElementVNode("div", null, [
                  (_openBlock(), _createBlock(_Suspense, null, {
                    default: _withCtx(() => [
                      _createVNode(UserListWidget, { "label-class": "text-dark font-inter-bold text-h5 q-mt-sm" })
                    ]),
                    _: 1
                  }))
                ])
              ], 2)
            ])
          ]),
          _createElementVNode("div", {
            class: "col-12 col-sm-6",
            style: _normalizeStyle({height:  _ctx.$q.screen.gt.sm ? '580px' : undefined})
          }, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", {
                class: _normalizeClass({'full-width': _ctx.$q.screen.lt.sm})
              }, [
                _createVNode(_component_q_tabs, {
                  modelValue: swiperCardsTab.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((swiperCardsTab).value = $event)),
                  class: "text-grey-6",
                  "indicator-color": "secondary",
                  "no-caps": "",
                  dense: "",
                  stretch: "",
                  "active-color": "grey-9"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_tab, {
                      name: "vehicles",
                      icon: "fas fa-car",
                      label: "Fahrzeuge"
                    }),
                    _createVNode(_component_q_tab, {
                      name: "market",
                      icon: "fas fa-tag",
                      label: "Flohmarkt"
                    }),
                    _createVNode(_component_q_tab, {
                      name: "services",
                      icon: "fas fa-store",
                      label: "Dienstleister"
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ], 2),
              _createElementVNode("div", _hoisted_11, [
                (swiperCardsTab.value === 'vehicles')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createVNode(CSwiperCardsAdverts)
                    ]))
                  : _createCommentVNode("", true),
                (swiperCardsTab.value === 'market')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createVNode(CSwiperCardsItems)
                    ]))
                  : _createCommentVNode("", true),
                (swiperCardsTab.value === 'services')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createVNode(CSwiperCardsServices)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ], 4)
        ])
      ]),
      _createElementVNode("section", _hoisted_15, [
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "text-center text-caption text-muted" }, "Bekannt aus", -1)),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", null, [
            _createElementVNode("div", null, [
              _createElementVNode("img", {
                class: "trust-logos",
                alt: "ARD Logo",
                height: "35px",
                src: require('assets/landing/bekannt_aus/ARD_Logo_2019.svg')
              }, null, 8, _hoisted_17)
            ]),
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-muted text-caption text-uppercase" }, "Campingcheck", -1))
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", null, [
              _createElementVNode("img", {
                class: "trust-logos",
                alt: "Charivari Logo",
                height: "50px",
                src: require('assets/landing/bekannt_aus/charivari.webp')
              }, null, 8, _hoisted_18)
            ])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", null, [
              _createElementVNode("img", {
                style: {"opacity":"0.8"},
                class: "trust-logos",
                alt: "CamperVans Logo",
                height: "50px",
                src: require('assets/landing/bekannt_aus/campervans_logo.webp')
              }, null, 8, _hoisted_19)
            ])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", null, [
              _createElementVNode("img", {
                class: "trust-logos",
                alt: "Campermen Logo",
                height: "50px",
                src: require('assets/landing/bekannt_aus/campermen_logo.webp')
              }, null, 8, _hoisted_20)
            ])
          ])
        ])
      ]),
      _createElementVNode("section", _hoisted_21, [
        _createElementVNode("div", _hoisted_22, [
          _cache[22] || (_cache[22] = _createElementVNode("div", {
            class: "text-center",
            style: {"margin-bottom":"104px","margin-top":"calc(104px - 48px)"}
          }, [
            _createElementVNode("h2", { class: "text-h1 text-weight-bolder highlighted-title" }, " Neue & gebrauchte Camper ")
          ], -1)),
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", _hoisted_25, [
                _cache[7] || (_cache[7] = _createElementVNode("div", { class: "col-12 col-sm-6" }, [
                  _createElementVNode("div", { class: "text-h1 text-weight-light" }, [
                    _createTextVNode("Suche nach dem passenden "),
                    _createElementVNode("span", { class: "text-bold" }, "Camper-Ausbau")
                  ])
                ], -1)),
                _createElementVNode("div", _hoisted_26, [
                  _createVNode(IndexExtrasSliderNoText, {
                    size: "1.5rem",
                    style: {"max-width":"100%"},
                    class: "overflow-hidden"
                  })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", {
                class: "row q-col-gutter-xl items-center",
                style: _normalizeStyle({'flex-direction': _ctx.$q.screen.lt.sm ? 'column-reverse' : undefined})
              }, [
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("div", _hoisted_29, [
                    (_ctx.$q.screen.gt.sm)
                      ? (_openBlock(), _createBlock(_component_q_img, {
                          key: 0,
                          alt: "Ausbau Details im Camper",
                          src: require('src/assets/landing/ausbau_details.webp'),
                          style: {"width":"100%"}
                        }, null, 8, ["src"]))
                      : (_openBlock(), _createBlock(_component_q_img, {
                          key: 1,
                          src: require('src/assets/landing/ausbau_details2.webp'),
                          alt: "Ausbau Details im Camper",
                          style: {"width":"100%","max-width":"300px"}
                        }, null, 8, ["src"]))
                  ])
                ]),
                _cache[8] || (_cache[8] = _createElementVNode("div", { class: "col-12 col-sm-6" }, [
                  _createElementVNode("div", { class: "text-h1 text-weight-light" }, [
                    _createTextVNode(" Mit vielen "),
                    _createElementVNode("span", { class: "text-bold" }, "Einzelheiten"),
                    _createTextVNode(" zum Ausbau ")
                  ])
                ], -1))
              ], 4)
            ]),
            _createElementVNode("div", _hoisted_30, [
              _createElementVNode("div", _hoisted_31, [
                _createElementVNode("div", _hoisted_32, [
                  _cache[10] || (_cache[10] = _createElementVNode("div", { class: "text-h1 text-weight-light" }, [
                    _createTextVNode(" Oder "),
                    _createElementVNode("span", { class: "text-bold" }, "inseriere"),
                    _createTextVNode(" deinen Camper "),
                    _createElementVNode("span", { class: "text-bold" }, "kostenlos")
                  ], -1)),
                  _cache[11] || (_cache[11] = _createElementVNode("div", { class: "text-h4 text-weight-light q-mt-sm" }, [
                    _createTextVNode("In nur "),
                    _createElementVNode("span", { class: "text-bold" }, "5 Minuten"),
                    _createTextVNode(" ist dein Fahrzeuginserat online! ")
                  ], -1)),
                  _cache[12] || (_cache[12] = _createElementVNode("div", { class: "q-mt-sm" }, [
                    _createElementVNode("a", {
                      target: "_blank",
                      href: "https://campertrader.de/blog/camperverkauf/"
                    }, "Mehr zum Camper Verkauf kannst du hier nachlesen")
                  ], -1)),
                  _createElementVNode("div", _hoisted_33, [
                    _createVNode(_component_q_btn, {
                      to: {name: 'createAdvert'},
                      rounded: "",
                      color: "primary",
                      "no-caps": ""
                    }, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode("Jetzt anmelden und inserieren ")
                      ])),
                      _: 1
                    })
                  ])
                ]),
                _cache[13] || (_cache[13] = _createElementVNode("div", { class: "col-12 col-sm-6" }, [
                  _createElementVNode("div", { class: "c-fancy-background rounded-borders bordered flex flex-center" }, [
                    _createElementVNode("div", {
                      class: "flex no-wrap items-center justify-center",
                      style: {"min-width":"0","column-gap":"18px"}
                    }, [
                      _createElementVNode("div", { style: {"font-size":"4rem","margin-top":"-20px"} }, "🚐"),
                      _createElementVNode("div", {
                        style: {"font-size":"4rem"},
                        class: "c-shake-hands"
                      }, "🤝"),
                      _createElementVNode("div", { style: {"font-size":"4rem"} }, "💸")
                    ])
                  ])
                ], -1))
              ])
            ]),
            _createElementVNode("div", _hoisted_34, [
              _createElementVNode("div", {
                class: "row q-col-gutter-xl items-center",
                style: _normalizeStyle({'flex-direction': _ctx.$q.screen.lt.sm ? 'column-reverse' : undefined})
              }, [
                _createElementVNode("div", _hoisted_35, [
                  _createElementVNode("div", _hoisted_36, [
                    _createElementVNode("div", _hoisted_37, [
                      _createVNode(_component_q_img, {
                        src: require('src/assets/etc/search_agent.jpg'),
                        alt: "Suchaufträge Beispiel"
                      }, null, 8, ["src"])
                    ])
                  ])
                ]),
                _cache[14] || (_cache[14] = _createElementVNode("div", { class: "col-12 col-sm-6" }, [
                  _createElementVNode("div", { class: "text-h1 text-weight-light" }, [
                    _createElementVNode("span", { class: "text-bold" }, "Suchauftrag"),
                    _createTextVNode(" erstellen und "),
                    _createElementVNode("span", { class: "text-bold" }, "direkt benachrichtigt"),
                    _createTextVNode(" werden ")
                  ]),
                  _createElementVNode("div", { class: "text-h4 text-weight-light q-mt-sm" }, "So verpasst du kein Angebot mehr")
                ], -1))
              ], 4)
            ]),
            _createElementVNode("div", _hoisted_38, [
              _cache[21] || (_cache[21] = _createElementVNode("div", { class: "text-center q-mb-xl text-h3 text-weight-light" }, "Welcher Camper-Typ bist du?", -1)),
              _createElementVNode("div", _hoisted_39, [
                _createElementVNode("div", _hoisted_40, [
                  _createElementVNode("img", {
                    height: "50px",
                    alt: "Campervan Icon",
                    src: require('src/assets/category_select_icons/icon_camper.svg')
                  }, null, 8, _hoisted_41),
                  _cache[15] || (_cache[15] = _createElementVNode("h4", { class: "text-h4 text-weight-lighter text-dark" }, "Campervan", -1))
                ]),
                _createElementVNode("div", _hoisted_42, [
                  _createElementVNode("img", {
                    height: "50px",
                    alt: "Alkoven Wohnmobil Icon",
                    src: require('src/assets/category_select_icons/icon_wohnmobil_alkoven.svg')
                  }, null, 8, _hoisted_43),
                  _cache[16] || (_cache[16] = _createElementVNode("h4", { class: "text-h4 text-weight-lighter text-dark" }, "Alkoven Wohnmobil", -1))
                ]),
                _createElementVNode("div", _hoisted_44, [
                  _createElementVNode("img", {
                    height: "50px",
                    alt: "Mini Camper Icon",
                    src: require('src/assets/category_select_icons/icon_tiny_camper.svg')
                  }, null, 8, _hoisted_45),
                  _cache[17] || (_cache[17] = _createElementVNode("h4", { class: "text-h4 text-weight-lighter text-dark" }, "Mini Camper", -1))
                ]),
                _createElementVNode("div", _hoisted_46, [
                  _createElementVNode("img", {
                    height: "50px",
                    alt: "Integriert Wohnmobil Icon",
                    src: require('src/assets/category_select_icons/icon_wohnmobil_integriert.svg')
                  }, null, 8, _hoisted_47),
                  _cache[18] || (_cache[18] = _createElementVNode("h4", { class: "text-h4 text-weight-lighter text-dark" }, "Integriert Wohnmobil", -1))
                ]),
                _createElementVNode("div", _hoisted_48, [
                  _createElementVNode("img", {
                    height: "50px",
                    alt: "Luxus Liner Icon",
                    src: require('src/assets/category_select_icons/icon_liner.svg')
                  }, null, 8, _hoisted_49),
                  _cache[19] || (_cache[19] = _createElementVNode("h4", { class: "text-h4 text-weight-lighter text-dark" }, "Luxus Liner", -1))
                ]),
                _createVNode(_component_q_btn, {
                  to: {name: 'search'},
                  class: "bg-grey-3 rounded-borders q-pa-md c-standout",
                  style: {"height":"105px"},
                  unelevated: ""
                }, {
                  default: _withCtx(() => _cache[20] || (_cache[20] = [
                    _createElementVNode("span", { class: "text-h4 text-weight-lighter text-dark" }, [
                      _createTextVNode("Weitere"),
                      _createElementVNode("br"),
                      _createTextVNode("entdecken")
                    ], -1)
                  ])),
                  _: 1
                })
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("section", _hoisted_50, [
        _createElementVNode("div", _hoisted_51, [
          _createElementVNode("div", _hoisted_52, [
            _createElementVNode("h2", _hoisted_53, [
              _createElementVNode("span", _hoisted_54, [
                _createElementVNode("span", _hoisted_55, [
                  _createVNode(_component_q_badge, {
                    color: "tertiary",
                    rounded: ""
                  }, {
                    default: _withCtx(() => _cache[23] || (_cache[23] = [
                      _createTextVNode("Jetzt neu!")
                    ])),
                    _: 1
                  })
                ]),
                _cache[24] || (_cache[24] = _createTextVNode(" Flohmarkt "))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_56, [
            _createElementVNode("div", _hoisted_57, [
              _createElementVNode("div", _hoisted_58, [
                _createElementVNode("div", _hoisted_59, [
                  _cache[26] || (_cache[26] = _createElementVNode("div", { class: "text-h1 text-weight-light" }, [
                    _createTextVNode(" Wachsende Auswahl an neuem und gebrauchtem "),
                    _createElementVNode("span", { class: "text-bold" }, "Camping Zubehör")
                  ], -1)),
                  _createElementVNode("div", _hoisted_60, [
                    _createVNode(_component_q_btn, {
                      color: "primary",
                      to: {name: 'search', query: {t: 'market'}},
                      rounded: "",
                      "no-caps": ""
                    }, {
                      default: _withCtx(() => _cache[25] || (_cache[25] = [
                        _createTextVNode("Zum Flohmarkt ")
                      ])),
                      _: 1
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_61, [
                  _createVNode(BoxEmojiAnimation)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_62, [
              _createElementVNode("div", {
                class: "row q-col-gutter-xl items-center",
                style: _normalizeStyle({'flex-direction': _ctx.$q.screen.lt.sm ? 'column-reverse' : undefined})
              }, [
                _createElementVNode("div", _hoisted_63, [
                  _createElementVNode("div", _hoisted_64, [
                    _createElementVNode("div", _hoisted_65, [
                      _cache[27] || (_cache[27] = _createElementVNode("div", { class: "market-emoji-box" }, "📦", -1)),
                      _cache[28] || (_cache[28] = _createElementVNode("div", { style: {"font-size":"4rem"} }, "🏠", -1)),
                      _createElementVNode("div", null, [
                        _createElementVNode("img", {
                          style: {"width":"40px"},
                          alt: "Camper Trader Logo",
                          src: require('src/assets/campertrader/logo/campertrader_icon_ocean.svg')
                        }, null, 8, _hoisted_66)
                      ]),
                      _cache[29] || (_cache[29] = _createElementVNode("div", { style: {"font-size":"4rem"} }, "🙋‍♀️", -1))
                    ])
                  ])
                ]),
                _cache[30] || (_cache[30] = _createElementVNode("div", { class: "col-12 col-sm-6" }, [
                  _createElementVNode("div", { class: "text-h1 text-weight-light" }, [
                    _createTextVNode(" Oder "),
                    _createElementVNode("span", { class: "text-bold" }, "verkaufe"),
                    _createTextVNode(" dein Camping Zubehör "),
                    _createElementVNode("span", { class: "text-bold" }, "kostenlos")
                  ]),
                  _createElementVNode("div", { class: "text-h4 text-weight-light q-mt-sm" }, "Mehr Platz für neues Camping Zubehör")
                ], -1))
              ], 4)
            ])
          ])
        ])
      ]),
      _createElementVNode("section", _hoisted_67, [
        _createElementVNode("div", _hoisted_68, [
          _cache[34] || (_cache[34] = _createElementVNode("div", {
            class: "text-center",
            style: {"margin-bottom":"104px","margin-top":"calc(104px - 48px)"}
          }, [
            _createElementVNode("h2", { class: "text-h1 text-weight-bolder highlighted-title" }, " Dienstleister in der Nähe ")
          ], -1)),
          _createElementVNode("div", _hoisted_69, [
            _createElementVNode("div", _hoisted_70, [
              _createElementVNode("div", _hoisted_71, [
                _cache[31] || (_cache[31] = _createElementVNode("div", { class: "col-12 col-sm-6" }, [
                  _createElementVNode("div", { class: "text-h1 text-weight-light" }, [
                    _createTextVNode(" Den passenden "),
                    _createElementVNode("span", { class: "text-bold" }, [
                      _createTextVNode("Dienstleister "),
                      _createElementVNode("br"),
                      _createTextVNode("in der Nähe")
                    ]),
                    _createTextVNode(" finden ")
                  ]),
                  _createElementVNode("div", { class: "text-h4 text-weight-light q-mt-sm" }, "Ob Werkstatt, Online-Shop, Modulhersteller, Gasprüfer oder Autohändler ")
                ], -1)),
                _createElementVNode("div", _hoisted_72, [
                  _createVNode(StartServicesAnimation)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_73, [
              _createElementVNode("div", {
                class: "row q-col-gutter-xl items-center",
                style: _normalizeStyle({'flex-direction': _ctx.$q.screen.lt.sm ? 'column-reverse' : undefined})
              }, [
                _createElementVNode("div", _hoisted_74, [
                  _createVNode(StartServicesAnimationSearch)
                ]),
                _createElementVNode("div", _hoisted_75, [
                  _cache[33] || (_cache[33] = _createElementVNode("div", { class: "text-h1 text-weight-light" }, [
                    _createTextVNode(" Alle Infos und Leistungen auf einer Seite. "),
                    _createElementVNode("span", { class: "text-bold" }, "Kein stundenlanges Suchen!")
                  ], -1)),
                  _createElementVNode("div", _hoisted_76, [
                    _createVNode(_component_q_btn, {
                      color: "primary",
                      "no-caps": "",
                      rounded: "",
                      to: {name: 'search', query: {t: 'dealer'}}
                    }, {
                      default: _withCtx(() => _cache[32] || (_cache[32] = [
                        _createTextVNode("Zur Dienstleister-Suche ")
                      ])),
                      _: 1
                    })
                  ])
                ])
              ], 4)
            ])
          ])
        ])
      ]),
      _createElementVNode("section", null, [
        _createElementVNode("div", _hoisted_77, [
          _cache[40] || (_cache[40] = _createElementVNode("div", {
            class: "text-center",
            style: {"margin":"104px 0"}
          }, [
            _createElementVNode("h2", { class: "text-h1 text-weight-bolder highlighted-title" }, " Wir und unsere Mission ")
          ], -1)),
          _createElementVNode("div", _hoisted_78, [
            _cache[38] || (_cache[38] = _createElementVNode("div", { class: "" }, [
              _createElementVNode("p", { class: "text-h1 q-mb-lg text-weight-light" }, [
                _createTextVNode("Unsere "),
                _createElementVNode("strong", null, "Mission"),
                _createTextVNode(":")
              ]),
              _createElementVNode("p", { class: "text-h3 text-weight-light q-mb-lg" }, [
                _createTextVNode("Euch den "),
                _createElementVNode("strong", null, "Kauf oder Verkauf eines Campers"),
                _createTextVNode(" oder eures "),
                _createElementVNode("strong", null, "Camping Zubehörs"),
                _createTextVNode(" so einfach und effizient wie möglich zu machen.")
              ]),
              _createElementVNode("p", { class: "text-h4 text-weight-light" }, " Mit dem Fokus auf Fahrzeugkauf und -verkauf sowie einem wachsenden Angebot von Camping-Zubehör und Dienstleistungen, wollen wir dir die bestmögliche Plattform bieten. Bei uns kannst du in einer übersichtlichen und benutzerfreundlichen Umgebung mit anderen Campern aus der Community in den Austausch treten."),
              _createElementVNode("p", { class: "q-mt-sm" }, [
                _createElementVNode("a", { href: "https://campertrader.de/blog/tipps-camperverkauf/" }, "Hier findest du Tipps zum Camper Verkauf")
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_79, [
              _createElementVNode("div", _hoisted_80, [
                _createElementVNode("div", _hoisted_81, [
                  _createElementVNode("div", _hoisted_82, [
                    _createElementVNode("div", _hoisted_83, [
                      _createVNode(_component_q_img, {
                        style: {"width":"100%","max-width":"240px","aspect-ratio":"1","border-radius":"50%"},
                        src: require('assets/team/sandra_avatar.jpg'),
                        alt: "Image of Sandra Tröndle"
                      }, null, 8, ["src"]),
                      _cache[35] || (_cache[35] = _createElementVNode("div", {
                        class: "col-grow full-width",
                        style: {"margin-top":"-25px","z-index":"1"}
                      }, [
                        _createElementVNode("div", {
                          class: "inline-block full-height bg-white rounded-borders text-dark bordered q-px-sm q-py-xs",
                          style: {"width":"100%","max-width":"320px"}
                        }, [
                          _createElementVNode("p", { class: "text-bold q-mb-sm text-h4" }, "Sandra 🌞"),
                          _createElementVNode("p", { class: "text-caption" }, "\"Ich bereise mit meinem Bulli Europa, liebe und lebe das Camping seit meiner Kindheit.\"")
                        ])
                      ], -1))
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_84, [
                    _createElementVNode("div", _hoisted_85, [
                      _createVNode(_component_q_img, {
                        style: {"width":"100%","max-width":"240px","aspect-ratio":"1","border-radius":"50%"},
                        src: require('assets/team/dominik_avatar.jpg'),
                        alt: "Image of Dominik Lysiak"
                      }, null, 8, ["src"]),
                      _cache[36] || (_cache[36] = _createElementVNode("div", {
                        class: "col-grow full-width",
                        style: {"margin-top":"-25px","z-index":"1"}
                      }, [
                        _createElementVNode("div", {
                          class: "inline-block full-height bg-white rounded-borders text-dark bordered q-px-sm q-py-xs",
                          style: {"width":"100%","max-width":"320px"}
                        }, [
                          _createElementVNode("p", { class: "text-bold q-mb-sm text-h4" }, "Dominik 💻"),
                          _createElementVNode("p", { class: "text-caption" }, "\"Ich bin fasziniert von Abenteuern in der Natur, insbesondere vom Campen und Entdecken neuer Orte.\"")
                        ])
                      ], -1))
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_86, [
                    _createElementVNode("div", _hoisted_87, [
                      _createVNode(_component_q_img, {
                        style: {"width":"100%","max-width":"240px","aspect-ratio":"1","border-radius":"50%"},
                        src: require('assets/team/luna_avatar.jpg'),
                        alt: "Image of Luna the team dog"
                      }, null, 8, ["src"]),
                      _cache[37] || (_cache[37] = _createElementVNode("div", {
                        class: "col-grow full-width",
                        style: {"margin-top":"-25px","z-index":"1"}
                      }, [
                        _createElementVNode("div", {
                          class: "inline-block full-height bg-white rounded-borders text-dark bordered q-px-md q-py-xs",
                          style: {"width":"100%","max-width":"320px"}
                        }, [
                          _createElementVNode("p", { class: "text-bold q-mb-sm text-h4" }, "Luna 🦴"),
                          _createElementVNode("p", null, "\"Leckerli? ...\"")
                        ])
                      ], -1))
                    ])
                  ])
                ])
              ])
            ]),
            _cache[39] || (_cache[39] = _createElementVNode("div", { class: "row q-col-gutter-xl items-center" }, [
              _createElementVNode("div", { class: "col-12 col-sm-6" }, [
                _createElementVNode("div", { class: "text-h1 text-weight-light" }, [
                  _createElementVNode("strong", null, "Wie Camper Trader entstand"),
                  _createTextVNode(" und was Tinder damit zu tun hatte ... ")
                ])
              ]),
              _createElementVNode("div", { class: "col-12 col-sm-6" }, [
                _createElementVNode("div", null, [
                  _createElementVNode("div", { class: "text-bold" }, "Jetzt als Podcast anhören"),
                  _createElementVNode("div", { class: "text-h3 q-mb-md text-weight-light" }, " \"Manchmal öffnen sich neue Türen erst, wenn du selbst die alten zumachst\" "),
                  _createElementVNode("div", null, [
                    _createElementVNode("iframe", {
                      style: {"border-radius":"20px"},
                      src: "https://open.spotify.com/embed/episode/62ogOEWR5VVaYIaTkXRXgJ?utm_source=generator&theme=0",
                      width: "100%",
                      height: "152",
                      frameBorder: "0",
                      allowfullscreen: "true",
                      allow: "autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture",
                      title: "Spotify Embed",
                      loading: "lazy"
                    }),
                    _createElementVNode("div", {
                      class: "flex",
                      style: {"column-gap":"12px"}
                    }, [
                      _createElementVNode("div", null, [
                        _createElementVNode("a", {
                          rel: "external nofollow noreferrer noopener",
                          target: "_blank",
                          href: "https://podcasts.apple.com/de/podcast/em137-manchmal-%C3%B6ffnen-sich-neue-t%C3%BCren-erst-wenn-du/id1692915607?i=1000664319477"
                        }, "Auf apple.com anhören")
                      ]),
                      _createElementVNode("div", null, [
                        _createElementVNode("a", {
                          rel: "external nofollow noreferrer noopener",
                          target: "_blank",
                          href: "https://shows.acast.com/648ad353889ad00011fbc8cc/episodes/em137-manchmal-offnen-sich-neue-turen-erst-wenn-du-selbst-di?"
                        }, "Auf acast.com anhören")
                      ])
                    ])
                  ])
                ])
              ])
            ], -1))
          ])
        ])
      ]),
      _withDirectives(_createElementVNode("section", _hoisted_88, [
        _createElementVNode("div", _hoisted_89, [
          _createElementVNode("div", _hoisted_90, [
            _createElementVNode("div", {
              class: _normalizeClass([{container: _ctx.$q.screen.lt.sm}, "text-weight-light text-dark text-h3"])
            }, " Partner die uns vertrauen ", 2)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass({container: !_ctx.$q.screen.lt.sm})
          }, [
            _createVNode(PartnerCarousel, {
              "onUpdate:modelValue": _cache[1] || (_cache[1] = val => partner.value = val)
            })
          ], 2)
        ])
      ], 512), [
        [_vShow, partner.value?.length]
      ]),
      _createElementVNode("section", _hoisted_91, [
        _createVNode(TestimonialWidget, { class: "q-py-xl" })
      ]),
      _createVNode(BlogFeed),
      _createVNode(StartFaqs)
    ]),
    _: 1
  }))
}
}

})